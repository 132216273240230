import React, { useRef, useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SelectionDialog from "./SelectionDialog";
import sfmToHtml from "./SFM/sfmParser";
import usxToHtml from "./SFM/usxParser";
import * as recent from "../utilities/recent.js";
import * as database from "../utilities/database.js";
import * as io from "../utilities/io.js";
import { handleMatomoCustomEvent } from "../utilities/bookkeeping.js";
import { useHistory } from "../context/HistoryContext.js";
import setupTooltipAdjustments from "./SFM/Tooltips";
import "./SFM/SFM.css";

<link rel="manifest" href="/manifest.json"></link>;
const topOffset = 50;

const SfmViewer = React.forwardRef((props, ref) => {
	const [versePopupOpen, setVersePopupOpen] = useState(false);
	const [verse, setVerse] = useState("1:1");
	const [data, setData] = useState("<span class='loader'></span>");
	const [dataReady, setDataReady] = useState(false);
	const history = useHistory();

	let nodeRef = useRef(props.node);
	let anchors = useRef(null);
	let jumpToRef = useRef(null);
	let jumpToTop = useRef(0);
	// console.log("SfmViewer");
	// console.log(nodeRef.current);
	// TODO: lower frequency of calling recent.update by tracking last history pushed
	// and only calling if chapter changes or verse changes by more than 1

	// wrap functions to limit unnecessary re-renders
	// otherwise they are redefined on each render

	// sets the background color of the overlay to be the same as menubar
	// overlay shows chapter:verse atop the "HeartLMD" menubar label
	const fixBackground = useCallback(() => {
		anchors.current = document.getElementsByClassName("HTcanOverlay");
		jumpToRef.current = document.getElementById("SfmJumpTo");
		var style;
		// typically 2 results are returned, one is real other isn't, detect by non-zero width
		let element =
			anchors.current[0].width > 0 ? anchors.current[0] : anchors.current[1];
		element = element.parentNode.parentNode.parentNode.parentNode;
		if (window.getComputedStyle) {
			style = window.getComputedStyle(element);
		} else {
			style = element.currentStyle;
		}
		if (style) {
			//console.log (style.backgroundColor);
			jumpToRef.current.style.backgroundColor = style.backgroundColor;
		}
	}, []);

	// moves the chapter:verse overlay to track the position of
	// the floating menubar
	const trackToolbar = useCallback(() => {
		let rectangle = anchors.current[0].getBoundingClientRect();
		if (rectangle.width === 0) {
			// one or the other will be 0
			rectangle = anchors.current[1].getBoundingClientRect();
		}
		//console.log("anchors...");
		let top = rectangle.top;
		let left = rectangle.left;
		let width = rectangle.width;
		let jumpTo = jumpToRef.current;
		let prevTop = jumpTo.style.top;
		//console.log("window.innerWidth "+window.innerWidth);
		//console.log("new left "+left);
		jumpTo.style.top = top + "px";
		jumpTo.style.left = left + "px";
		jumpTo.style.width = width + "px";
		if (prevTop !== top + "px") {
			jumpToTop.current = top; // re-render until toolbar has stopped
			//console.log(" new top "+top);
		}
	}, []);

	// scroll the window so that the desired verse is near the top
	// uses chapter:verse div tags, but sometimes these are missing
	// such as the translation flow differs from the source flow
	// in this case, verses can be in [] and perhaps followed by
	// a letter a/b/c etc.
	const goToVerse = useCallback(
		(value) => {
			// console.log("go to verse '" + value + "'");
			let moved = false;
			let verseDiv = document.getElementById(value);
			if (!verseDiv) {
				// console.log("no initial match");
				// no match, try c:[v] formatting
				let index = value.indexOf(":");
				let id2 = value.substring(index) + ":[" + value.substring(index) + "]";
				verseDiv = document.getElementById(id2); // BOOK C:[V]
			}
			if (!verseDiv) {
				// no match, tagging must be 12-13 or 12a etc.
				moved = true;
				let previous = value;
				let tries = 20;
				do {
					// try to scroll to a previous verse or chapter
					previous = previousVerse(previous); // can return null if problem
					//console.log("trying "+previous);
					if (previous) verseDiv = document.getElementById(previous);
					else verseDiv = null;
				} while (!verseDiv && tries-- > 0);
				// could also check for higher verses
			}
			if (verseDiv) {
				//const offset = verseDiv.offsetTop-topOffset;
				setVerse(value);
				nodeRef.current.verse = value;
				recent.update(nodeRef.current);
				setTimeout(() => {
					//window.scrollTo({top: offset, left: 0, behavior:"auto"});
					//console.log("scrolling to "+offset);
					if (!moved) {
						let previous = previousVerse(value);
						//console.log("scroll to "+previous);
						let prevDiv = document.getElementById(previous);
						if (prevDiv) prevDiv.scrollIntoView();
					} else verseDiv.scrollIntoView();
					setTimeout(() => {
						trackToolbar();
					}, 200);
				}, 400);
			} else {
				console.log("no verseDiv?"); // more options could be tried (some day)
			}
		},
		[trackToolbar]
	);

	const previousVerse = (verse) => {
		if (verse === "1:1" || verse === "1:2") return "sfm-contents"; // go to top
		if (!verse) return null;
		let colon = verse.indexOf(":");
		let v = verse.substring(colon + 1);
		if (v > 1) {
			v--;
			return verse.substring(0, colon + 1) + v;
		} else {
			let space = verse.indexOf(" "); // book-shortname c:v
			let c = parseInt(verse.substring(space + 1, colon));
			if (c > 1) {
				c--;
				v = nodeRef.current.range[c - 1]; // highest vers in chapter c
				return verse.substring(0, space + 1) + c + ":" + v;
			}
		}
		return null; // don't move if failed to find a previous value
	};

	// track history of last page being read, and update history (external routine)
	const updateVerse = useCallback(() => {
		// get verse at current top of scroll
		// TODO: instead of always doing an exhaustive search,
		// start from current verse, see where it is (y.offsetTop) and
		// search nearby siblings (better for slow scrolling, as when reading)
		let ic = 0;
		let y0 = window.scrollY + topOffset;
		let y;
		do {
			ic++;
			y = document.getElementById(ic + ":1"); // tagged #:# (2 numbers)
			if (!y) y = document.getElementById("c-" + ic); // tagged c-#:#
			//if (y) console.log(y0+", "+ y.offsetTop);
		} while (y && y.offsetTop < y0 && ic < nodeRef.current.range.length - 1);
		if (ic > 1) ic--; // back to to previous chapter and then check verses
		let iv = 0;
		let ivMax = 1;
		do {
			// alternative: find c:1 and then look for siblings?
			iv++;
			y = document.getElementById(ic + ":" + iv);
			if (!y) y = document.getElementById(ic + ":[" + iv + "]");
			if (y) ivMax = iv; // highest numbered verse that was found
			//console.log("check "+ic+":"+iv+" "+y+" "+nodeRef.current.range[ic-1]);
		} while ((!y || y.offsetTop < y0) && iv < nodeRef.current.range[ic - 1] - 1); // handle scrambled verses
		if (!y) iv = ivMax;
		let v = ic + ":" + iv;
		// console.log("at " + v);
		// TODO: now that you are close, back up a few siblings of class "sfm-verse" until you fall
		// off the top of the page, looking for id tags of the form #:[#] or #:[#a-#f] or similar
		// TODO: move verse label into separate component so only it needs to be updated
		setVerse(v); // triggers re-render so label is correct
		nodeRef.current.verse = v;
		recent.update(nodeRef.current); // update recently viewed
		history.replaceState({ ...history.state(), s: window.scrollY });
	}, [history]);

	useEffect(() => {
		let scrollY = history.state().s;
		if (scrollY) window.scrollTo({ top: scrollY });
		// return () => {
		// 	console.log("SfmViewer unmounting, useEffect returned routine..."); // diagnostic
		// 	console.log(history.state()); // this is already the next navigation place!
		// };
	}, [history]);

	useEffect(() => {
		// runs after render to start fetching data
		try {
			// get referenced data, either from IndexedDB or from web (or web cache)
			let file = nodeRef.current.file;
			let fetch = io.getItem(file);
			let skipCache = false;
			fetch
				.then((result) => {
					//console.log(result);
					if (result && !result.startsWith("<p")) {
						// if starts with "<p" then came from cache
						if (
							result.startsWith("offline") ||
							result.startsWith("Error") ||
							result.startsWith("ERROR")
						) {
							skipCache = true;
						} else if (
							nodeRef.current.mediaType &&
							nodeRef.current.mediaType === "sfm"
						) {
							result = sfmToHtml(result);
							if (result.startsWith("Error")) skipCache = true;
						} else {
							result = usxToHtml(result);
							if (result.startsWith("Error")) skipCache = true;
						}
						// start background task to cache the converted file,
						// returns a promise quickly
						if (!skipCache)
							database.putToDB("itemCache", nodeRef.current.file, result);
					}
					if (result && result.length < 200) {
						// short is probably an error message
						console.error("SfmViewer received bad data:");
						console.log(result);
					}
					setData(result); // triggers a re-render with new data (might be error string)
					setDataReady(true);
					setTimeout(setupTooltipAdjustments, 1000); // should be after next render
				})
				.catch((error) => {
					console.log(
						"caught error in SfmViewer, status " +
							error.status +
							" " +
							error.message
					);
					console.log(error);
					setData(
						"<p style='margin:18px; color:DarkRed'>" + error.message + "</p"
					);
				});
		} catch (error) {
			console.error("Error thrown in SfmViewer " + error.message);
			setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p");
		}
		fixBackground(); // done after page has first rendered with no data
		if (!nodeRef.current.verse) {
			// bookkeeping for starting verse
			nodeRef.current.verse = "1:1";
			recent.update(nodeRef.current); // initial 'recently view' update even though not yet rendered
		}
	}, [props.node, fixBackground]);

	useEffect(() => {
		if (dataReady) {
			if (nodeRef.current.verse && nodeRef.current.verse !== "1:1")
				goToVerse(nodeRef.current.verse); // calling from useEffect delays this until render done!
		}
	}, [dataReady, goToVerse]);

	useEffect(() => {
		var timer = null;
		const handleScroll = (event) => {
			if (!timer) timer = setTimeout(processScroll, 250); // at most 4x per second
		};
		const processScroll = () => {
			timer = null;
			updateVerse();
			trackToolbar();
		};
		trackToolbar(); // initial tracking
		setTimeout(trackToolbar, 250); // once more in case it is still scrolling to starting verse
		window.addEventListener("scroll", handleScroll, { passive: true });
		window.onresize = (event) => {
			trackToolbar();
		};
		return () => {
			window.removeEventListener("scroll", handleScroll);
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [updateVerse, trackToolbar]);

	const closePopup = (value) => {
		//console.log("handleClose "+value);
		if (value) goToVerse(value);
		handleMatomoCustomEvent("User Action", "Bible Navigate", "GoTo Verse " + value);

		setVersePopupOpen(false);
	};

	return (
		<div {...props} ref={ref}>
			<div
				className="overlayHT"
				id="SfmJumpTo"
				style={{
					position: "sticky",
					top: "0px",
					backgroundColor: "primary.main"
				}}
			>
				<Button
					variant="dense"
					sx={{
						mr: 1,
						px: 1,
						py: 0.5,
						backgroundColor: "white",
						whiteSpace: "nowrap"
					}}
					onClick={() => {
						setVersePopupOpen(true);
					}}
				>
					{nodeRef.current.shortName + " " + verse}
				</Button>
			</div>
			{nodeRef.current.range && ( // add only after node has valid data
				<SelectionDialog
					node={nodeRef.current}
					open={versePopupOpen}
					onClose={closePopup}
				/>
			)}
			<Box sx={{ width: "100%", py: 4, px: 2, overflow: "hidden" }}>
				{" "}
				{/* smaller padding since floating div above consumes space */}
				<div id="sfm-contents" dangerouslySetInnerHTML={{ __html: data }} />
			</Box>
		</div>
	);
});

export default React.memo(SfmViewer);
